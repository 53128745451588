<template>
  <Form v-slot="{ invalid, changed }" class="upsell-item-form">
    <div class="grid-2 mb-32">
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          v-model="form.name"
          label="Name"
          placeholder="Name"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          v-model="form.price"
          label="Price"
          placeholder="Price"
          :mask="dollarsMask"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem>
        <Input
          is-textarea
          v-model="form.description"
          label="Description"
          placeholder="Description"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem>
        <ImageUploader
          v-model="form.image"
          label="Image"
          scope="upsellItems"
          :max-size="300"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem v-slot="{ isError }">
        <MultipleSelect
          v-model="form.taxes"
          :options="taxesOptions"
          label="Taxes"
          placeholder="Tax"
          :is-error="isError"
          :is-disabled="isDisabled"
          add-button-text="Add tax"
        />
      </FormItem>
    </div>
    <div class="upsell-item-form__actions">
      <Button
        v-if="!isDisabled"
        :is-disabled="invalid || !changed"
        :is-loading="isSubmitting"
        @click="submit"
      >
        Save
      </Button>
      <Guard permission="upsellItems.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && isEditing"
          is-outlined
          variant="danger"
          :is-disabled="isSubmitting"
          :is-loading="isDeleting"
          @click="handleDelete"
        >
          Delete
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import dialog from "@/plugins/dialog";
import { mapActions, mapState } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ImageUploader from "@/components/common/ImageUploader";
import Guard from "@/components/common/Guard";
import MultipleSelect from "@/components/common/MultipleSelect";
import { getCurrencyByCountryCode } from "@/helpers/utils";

export default {
  name: "UpsellItemForm",
  components: { MultipleSelect, Guard, ImageUploader },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      taxes: (state) => state.taxes.list,
      currencySymbol: (state) =>
        getCurrencyByCountryCode(state.venues.selectedVenue?.country).symbol,
    }),
    dollarsMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: this.currencySymbol,
      });
    },
    taxesOptions() {
      return this.taxes.map((tax) => ({
        value: tax.id,
        name: `${tax.name} (${tax.rate}%)`,
      }));
    },
    isEditing() {
      return this.$route.name === "EditUpsellItem";
    },
  },
  created() {
    this.fetchTaxes();
  },
  methods: {
    ...mapActions({
      deleteUpsellItem: "upsellItems/deleteUpsellItem",
      fetchTaxes: "taxes/fetchTaxes",
    }),
    submit() {
      const priceNumber = +this.form.price.replace(/[CA$,]/g, "");
      this.$emit("submit", {
        ...this.form,
        price: priceNumber,
      });
    },
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All upsell merchandise data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteUpsellItem(this.$route.params.id);
          await this.$router.push({ name: "UpsellItems" });
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-item-form {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__actions {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
