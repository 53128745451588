<template>
  <div class="edit-upsell-item-page">
    <BackTitle class="mb-40" confirm-click @click="goBack">
      {{ upsellItem ? upsellItem.name : "" }}
    </BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard
      v-else-if="upsellItem"
      permission="upsellItems.edit"
      v-slot="{ isAvailable }"
    >
      <UpsellItemForm
        :value="upsellItem"
        :is-submitting="isSubmitting"
        :is-disabled="!isAvailable"
        @submit="handleSubmit"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import UpsellItemForm from "@/components/upsell-items/UpsellItemForm";
import Guard from "@/components/common/Guard";
export default {
  name: "EditUpsellItemPage",
  components: { Guard, UpsellItemForm, BackTitle },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      upsellItem: (state) => state.upsellItems.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchUpsellItemById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchUpsellItemById: "upsellItems/fetchUpsellItemById",
      updateUpsellItem: "upsellItems/updateUpsellItem",
    }),
    goBack() {
      this.$router.push({
        name: "UpsellItems",
      });
    },
    async handleSubmit({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateUpsellItem({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-upsell-item-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
